<template>
  <div class="login">
    <div class="wrap">
      <div class="title">login_guard 管理后台</div>
      <a-input v-model:value="user_name" placeholder="账号"/>
      <a-input-password v-model:value="password" placeholder="密码" @keyup.enter="submit" :size="'small'"/>
      <div class="submit">
        <a-button type="primary" @click="submit" :loading="iconLoading">登录</a-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import http, { Res } from '@/utils/http'
import { message } from 'ant-design-vue'
import crypto from 'crypto-js'

export default defineComponent({
  name: 'Login',
  setup () {
    const user_name = ref('')
    const password = ref('')
    const router = useRouter()
    const iconLoading = ref(false)

    const submit = function() {
      const time = new Date().getTime()
      http('/tokenapi/login', {
        method: 'post',
        body: JSON.stringify({
          username: user_name.value,
          password: crypto.MD5(crypto.SHA1(password.value).toString(crypto.enc.Hex).toLocaleUpperCase() + time).toString(crypto.enc.Hex).toLocaleUpperCase(),
          timestamp: time
        })
      }).then((res: any) => {
        if (res.code === 200) {
          sessionStorage.setItem('token', res.data.token)
          sessionStorage.setItem('supper', res.data.supper)
          sessionStorage.setItem('username', user_name.value)
          router.push({ path: '/home/UserList' })
        }
        message.info(res.msg)
      })
    }


    return {
      user_name,
      password,
      iconLoading,
      submit
    }
  }
})
</script>

<style lang="scss">
.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrap {
    width: 350px;
    height: 400px;
    text-align: center;

    .title {
      width: 275px;
      height: 40px;
      font-size: 28px;
      text-align: center;
      line-height: 40px;
      margin: 0 auto 25px auto;
      font-weight: bold;
      color: #fff;
    }

    .ant-input, .ant-input-password {
      width: 350px;
      margin: 5px auto;
    }

    .submit {
      width: 100%;
      padding: 0 22px;
      text-align: center;
      margin-top: 20px;

      .ant-btn {
        width: 150px;
      }
    }
  }
}
</style>
