
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import http, { Res } from '@/utils/http'
import { message } from 'ant-design-vue'
import crypto from 'crypto-js'

export default defineComponent({
  name: 'Login',
  setup () {
    const user_name = ref('')
    const password = ref('')
    const router = useRouter()
    const iconLoading = ref(false)

    const submit = function() {
      const time = new Date().getTime()
      http('/tokenapi/login', {
        method: 'post',
        body: JSON.stringify({
          username: user_name.value,
          password: crypto.MD5(crypto.SHA1(password.value).toString(crypto.enc.Hex).toLocaleUpperCase() + time).toString(crypto.enc.Hex).toLocaleUpperCase(),
          timestamp: time
        })
      }).then((res: any) => {
        if (res.code === 200) {
          sessionStorage.setItem('token', res.data.token)
          sessionStorage.setItem('supper', res.data.supper)
          sessionStorage.setItem('username', user_name.value)
          router.push({ path: '/home/UserList' })
        }
        message.info(res.msg)
      })
    }


    return {
      user_name,
      password,
      iconLoading,
      submit
    }
  }
})
